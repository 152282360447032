.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
  --dark: #373349;
  --gap: 15px;
  --width: 160px;
  --height: var(--width);
}


body {
  margin: var(--gap);
}

.container > .a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  overflow: hidden;
}

 .a:hover {
  content: "";
  
 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: scale(1.05);

  transition-duration: .75s;
  background: linear-gradient(transparent,black);
  background-color: black;
}

.container > a:hover::after {
  transform: translateY(50%);
}

.container > .a > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 1s;
  color: white;
  font-size: 22px;
  text-transform: uppercase;
}

 /* .a:hover  {
  transform: scale(1.05);
  

} */

.container {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(var(--width), 1fr));
  grid-auto-rows: var(--height);
  grid-auto-flow: dense;
}

.horizontal {
  grid-column: span 2;
}

.vertical {
  grid-column: span 2;
  grid-row: span 4;
}

.big {
  grid-column: span 2;
  grid-row: span 3;
}

.gallery > .gallery__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-template-rows: repeat(6, 200px);
  grid-gap: 1rem;
  grid-auto-flow: dense;
}

.gallery > .gallery__list > li:nth-child(4n) {
  grid-column: span 2;
  grid-row: span 2;
}

.gallery > .gallery__list > li:nth-child(8n) {
  grid-column: span 3;
  grid-row: span 3;
}

.gallery > .gallery__list > li > figure {
  height: 100%;
}

.gallery > .gallery__list > li > figure > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/**/

main.gallery {
  padding: 2rem;
}

.gallery > h1 {
  font-size: 3rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-bottom: 1rem;
  color: #333333;
  font-weight: 100;
}
